import React, { useEffect, createRef } from "react";
import Layout from "../components/layout";
import silvergraypranaav from "../img/silvergray-pranaav.jpg";
import SEOHeader from "../components/seo-header";
export default function FourHundered() {
  return (
    <div>
      <SEOHeader
        title="Silvergray Photography by Pranaav BR"
        description="Pranaav produces unique, captivating photography, individually designed and curated for concepts and campaigns."
        pinterest="true"
      ></SEOHeader>

      <Layout>
        <div className="container">
          <div className="row">
            <div className="col-md-12"></div>
          </div>
        </div>
      </Layout>
    </div>
  );
}
